<template>
  <div class="inner-prop">
    <div class="hero">
      <span
        class="cvr-bg"
        style="background-image: url('/img/dummy/prop/1.jpg');"
      ></span>
      <div class="wrapper">
        <div class="hentry">
          <h2>{{ $t("findProperty.Title") }}</h2>
          <div class="txt">
            <p>
              {{ $t("findProperty.Subtitle") }}
            </p>
          </div>
          <!-- end of txt -->
        </div>
        <!-- end of hentry -->

        <div class="holder">
          <div class="nav">
            <a
              href="#"
              @click="form.transactionType = null"
              :class="{ current: form.transactionType == null }"
            >
              {{ $t("findProperty.FormSearch.TabAll") }}
            </a>
            <a
              href="#"
              @click="form.transactionType = 1"
              :class="{ current: form.transactionType == 1 }"
            >
              {{ $t("findProperty.FormSearch.TabRent") }}
            </a>
            <a
              href="#"
              @click="form.transactionType = 2"
              :class="{ current: form.transactionType == 2 }"
            >
              {{ $t("findProperty.FormSearch.TabRTO") }}
            </a>
            <a
              href="#"
              @click="form.transactionType = 3"
              :class="{ current: form.transactionType == 3 }"
            >
              {{ $t("findProperty.FormSearch.TabBuy") }}
            </a>
          </div>
          <!-- end of nav -->
          <div class="action">
            <form @submit.prevent="searchProperty">
              <div class="query">
                <i class="cvr-bg"></i>
                <input
                  type="text"
                  v-model="form.search"
                  :placeholder="$t('findProperty.FormSearch.Placeholder')"
                />
                <button type="submit" class="button" style="min-width: 115px">
                  {{ $t("findProperty.FormSearch.BtnSearch") }}
                </button>
              </div>
              <!-- end of query -->
              <div class="filter penta">
                <fieldset>
                  <select v-model="form.propertyType">
                    <option :value="null">
                      {{ $t("findProperty.FormSearch.PropertyType") }}
                    </option>
                    <option
                      v-for="(property, index) in propertyType"
                      :key="index"
                      :value="property.id"
                    >
                      {{ property.name }}
                    </option>
                  </select>
                </fieldset>
                <fieldset>
                  <select v-model="form.bedroom" :disabled="disableBedRoom">
                    <option :value="null">
                      {{ $t("findProperty.FormSearch.Bedrooms") }}
                    </option>
                    <option
                      v-for="(bedroom, index) in bedrooms"
                      :key="index"
                      :value="bedroom.value"
                    >
                      {{ bedroom.name }}
                    </option>
                  </select>
                </fieldset>
                <fieldset>
                  <select v-model="form.furnishedType">
                    <option :value="null">
                      {{ $t("findProperty.FormSearch.FurnishType") }}
                    </option>
                    <option
                      v-for="(furnished, index) in furnishedType"
                      :key="index"
                      :value="furnished.id"
                    >
                      {{ furnished.name }}
                    </option>
                  </select>
                </fieldset>
                <fieldset
                  v-if="
                    form.transactionType !== null && form.transactionType < 3
                  "
                >
                  <select v-model="form.rentPeriod">
                    <option :value="null">
                      {{ $t("findProperty.FormSearch.RentPeriod") }}
                    </option>
                    <option
                      v-for="(period, index) in rentPeriods"
                      :key="index"
                      :value="period.name"
                    >
                      {{ period.name }}
                    </option>
                  </select>
                </fieldset>
                <fieldset>
                  <a
                    href="javascript:;"
                    data-src="#popup-addprop"
                    class="cvr-bg-af"
                    data-fancybox
                  >
                    {{ $t("findProperty.FormSearch.AdditionalFilterButton") }}
                  </a>
                </fieldset>
              </div>
              <!-- end of filter-->
              <div
                id="popup-addprop"
                class="popup-addprop"
                style="display: none;"
              >
                <h3>
                  {{ $t("findProperty.FormSearch.AdditionalFilterTitle") }}
                </h3>
                <perfect-scrollbar>
                  <div class="fgroup">
                    <label>
                      {{ $t("findProperty.PopUpFilter.Province") }}
                    </label>
                    <fieldset>
                      <select v-model="form.province" @change="getCities">
                        <option></option>
                        <option
                          v-for="(province, index) in provinces"
                          :key="index"
                          :value="province.name"
                        >
                          {{ province.name }}
                        </option>
                      </select>
                    </fieldset>
                  </div>
                  <!-- end of fgroup -->
                  <div class="fgroup">
                    <label>
                      {{ $t("findProperty.PopUpFilter.City") }}
                    </label>
                    <fieldset>
                      <select v-model="form.city">
                        <option></option>
                        <option
                          v-for="(city, index) in cities"
                          :key="index"
                          :value="city.city"
                        >
                          {{ city.city }}
                        </option>
                      </select>
                    </fieldset>
                  </div>
                  <!-- end of fgroup -->
                  <template v-if="form.transactionType !== null">
                    <div class="fgroup" v-if="form.transactionType <= 2">
                      <label>
                        {{ $t("findProperty.FormSearch.MonthlyRentPrice") }}
                      </label>
                      <div class="gap">
                        <div class="field-size">
                          <b class="toleft">Rp</b>
                          <input
                            type="text"
                            v-model="form.minRentPrice"
                            placeholder="Minimum"
                            class="price"
                            @keyup="formatRupiah('minRentPrice')"
                          />
                        </div>
                        <!-- end of field min rent price -->
                        <div class="field-size">
                          <b class="toleft">Rp</b>
                          <input
                            type="text"
                            v-model="form.maxRentPrice"
                            placeholder="Maximum"
                            class="price"
                            @keyup="formatRupiah('maxRentPrice')"
                          />
                        </div>
                        <!-- end of field max rent price -->
                      </div>
                      <!-- end of gap -->
                    </div>
                    <div class="fgroup" v-if="form.transactionType == 2">
                      <label>
                        {{ $t("findProperty.FormSearch.SalesPrice") }}
                      </label>
                      <div class="gap">
                        <div class="field-size">
                          <b class="toleft">Rp</b>
                          <input
                            type="text"
                            v-model="form.minSalesPrice"
                            placeholder="Minimum"
                            class="price"
                            @keyup="formatRupiah('minSalesPrice')"
                          />
                        </div>
                        <!-- end of field min rent price -->
                        <div class="field-size">
                          <b class="toleft">Rp</b>
                          <input
                            type="text"
                            v-model="form.maxSalesPrice"
                            placeholder="Maximum"
                            class="price"
                            @keyup="formatRupiah('maxSalesPrice')"
                          />
                        </div>
                        <!-- end of field max rent price -->
                      </div>
                      <!-- end of gap -->
                    </div>
                    <div class="fgroup" v-if="form.transactionType == 3">
                      <label>Price</label>
                      <div class="gap">
                        <div class="field-size">
                          <b class="toleft">Rp</b>
                          <input
                            type="text"
                            v-model="form.minSalesPrice"
                            placeholder="Minimum"
                            class="price"
                            @keyup="formatRupiah('minSalesPrice')"
                          />
                        </div>
                        <!-- end of field min rent price -->
                        <div class="field-size">
                          <b class="toleft">Rp</b>
                          <input
                            type="text"
                            v-model="form.maxSalesPrice"
                            placeholder="Maximum"
                            class="price"
                            @keyup="formatRupiah('maxSalesPrice')"
                          />
                        </div>
                        <!-- end of field max rent price -->
                      </div>
                      <!-- end of gap -->
                    </div>
                  </template>
                  <div class="fgroup">
                    <label>{{ $t("findProperty.PopUpFilter.LandSize") }}</label>
                    <div class="gap">
                      <div class="field-size">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          v-model="form.minLandSize"
                          value=""
                          placeholder="Minimum"
                        />
                        <b>m <span class="meter-persegi">2</span></b>
                      </div>
                      <!-- end of field size -->
                      <div class="field-size">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          v-model="form.maxLandSize"
                          value=""
                          placeholder="Maximum"
                        />
                        <b>m <span class="meter-persegi">2</span></b>
                      </div>
                      <!-- end of field size -->
                    </div>
                    <!-- end of gap -->
                  </div>
                  <!--end of fgroup -->
                  <div class="fgroup">
                    <label>{{
                      $t("findProperty.PopUpFilter.BuildingSize")
                    }}</label>
                    <div class="gap">
                      <div class="field-size">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          v-model="form.minBuildingSize"
                          value=""
                          placeholder="Minimum"
                        />
                        <b>m <span class="meter-persegi">2</span></b>
                      </div>
                      <!-- end of field size -->
                      <div class="field-size">
                        <input
                          type="number"
                          min="0"
                          step="1"
                          v-model="form.maxBuildingSize"
                          value=""
                          placeholder="Maximum"
                        />
                        <b>m <span class="meter-persegi">2</span></b>
                      </div>
                      <!-- end of field size -->
                    </div>
                    <!-- end of gap -->
                  </div>
                  <!-- end of fgrop -->
                  <div class="fgroup">
                    <label>Number of Bathrooms</label>
                    <div class="field-check">
                      <label
                        ><input
                          type="checkbox"
                          name="bath"
                          value="1"
                          v-model="form.bathroom"
                        /><span>1</span></label
                      >
                      <label
                        ><input
                          type="checkbox"
                          name="bath"
                          value="2"
                          v-model="form.bathroom"
                        /><span>2</span></label
                      >
                      <label
                        ><input
                          type="checkbox"
                          name="bath"
                          value="3"
                          v-model="form.bathroom"
                        /><span>3</span></label
                      >
                      <label
                        ><input
                          type="checkbox"
                          name="bath"
                          value="4+"
                          v-model="form.bathroom"
                        /><span>4+</span></label
                      >
                    </div>
                  </div>
                  <!--end of fgroup -->
                  <div class="fgroup">
                    <label>Number of Car Lots</label>
                    <div class="field-check">
                      <label
                        ><input
                          type="checkbox"
                          name="carlot"
                          value="1"
                          v-model="form.carlot"
                        /><span>1</span></label
                      >
                      <label
                        ><input
                          type="checkbox"
                          name="carlot"
                          value="2"
                          v-model="form.carlot"
                        /><span>2</span></label
                      >
                      <label
                        ><input
                          type="checkbox"
                          name="carlot"
                          value="3"
                          v-model="form.carlot"
                        /><span>3</span></label
                      >
                      <label
                        ><input
                          type="checkbox"
                          name="carlot"
                          value="4+"
                          v-model="form.carlot"
                        /><span>4+</span></label
                      >
                    </div>
                  </div>
                  <!--end of fgroup -->
                  <div class="fgroup">
                    <label>Status</label>
                    <div class="field-check">
                      <label
                        ><input
                          type="checkbox"
                          name="status"
                          value="primary"
                          v-model="form.status"
                        /><span>Primary</span></label
                      >
                      <label
                        ><input
                          type="checkbox"
                          name="status"
                          value="secondary"
                          v-model="form.status"
                        /><span>Secondary</span></label
                      >
                    </div>
                  </div>
                  <!--end of fgroup -->
                </perfect-scrollbar>
                <div class="toright">
                  <a href="#" class="close" @click="resetPopUpForm">Reset</a>
                  <a href="#" class="button close">
                    {{ $t("findProperty.PopUpFilter.Btn") }}
                  </a>
                </div>
              </div>
              <!-- end of popup addprop -->
            </form>
          </div>
        </div>
        <!-- end of holder -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of hero -->

    <template v-if="recomendation">
      <div class="property">
        <div class="list">
          <div class="wrapper">
            <h2 class="clearfix">
              {{ $t("findProperty.FindAll.Title1") }}
            </h2>
            <div class="slider rto">
              <VueSlickCarousel
                ref="property1"
                v-if="property.rentToOwn.length"
                v-bind="{
                  infinite: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  dots: false,
                  autoplay: false,
                  speed: 500,
                  autoplaySpeed: 5000,
                  variableWidth: false,
                  responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: false
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: true
                      }
                    }
                  ]
                }"
              >
                <template v-for="(property, index) in property.rentToOwn">
                  <div class="item item-property" :key="index">
                    <figure>
                      <router-link
                        v-if="property.propertyType"
                        :to="`/property/${property.slug}#rent-to-own`"
                        class="type"
                        :style="
                          `background-color: ${property.propertyType.class}`
                        "
                      >
                        {{ property.propertyType.name }}
                      </router-link>
                      <a
                        @click="bookmark(property.id)"
                        class="cvr-bg pointer bookmark"
                        :class="{ marked: property.isBookmarked }"
                      ></a>
                      <router-link
                        :to="`/property/${property.slug}#rent-to-own`"
                      >
                        <template
                          v-if="
                            property.mediaDescription === null ||
                              property.mediaDescription.length === 0
                          "
                        >
                          <img src="/img/dummy/home/1.jpg" />
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              checkType(property.mediaDescription[0]) == 'video'
                            "
                          >
                            <video style="width: 100%" controls autoplay>
                              <source
                                :src="property.mediaDescription[0]"
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </template>
                          <template v-else>
                            <img :src="property.mediaDescription[0]" />
                          </template>
                        </template>
                      </router-link>
                    </figure>
                    <div class="caption">
                      <h3>
                        <router-link
                          :to="`/property/${property.slug}#rent-to-own`"
                        >
                          {{ property.name }}
                        </router-link>
                      </h3>
                      <div class="location cvr-bg-bf">
                        <span>{{ property.region }}, {{ property.city }}</span>
                        <span v-if="property.furnishedType">
                          {{ property.furnishedType.name }}
                        </span>
                      </div>
                      <!-- end of location -->
                      <strong class="price">
                        <b v-if="property.ratesAfterCalulate !== undefined">
                          Rp.
                          {{
                            property.ratesAfterCalulate.ratePerMonth
                              | convertToRupiah
                          }}
                        </b>
                        <small> / {{ $t("findProperty.Month") }}</small>
                        <em v-if="property.ratesAfterCalulate !== undefined">
                          Rp.
                          {{
                            property.ratesAfterCalulate
                              .propertyPriceEndOfRentalPeriod | convertToRupiah
                          }}
                        </em>
                      </strong>
                      <div class="amen">
                        <span
                          class="cvr-bg-bf ic-bed"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBedroom }}
                        </span>
                        <span
                          class="cvr-bg-bf ic-bath"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBathroom }}
                        </span>
                        <span class="cvr-bg-bf ic-land">
                          {{ property.landSize | convertToRupiah }} m<sup
                            >2</sup
                          >
                        </span>
                        <span class="cvr-bg-bf ic-build">
                          {{ property.buildingSize | convertToRupiah }} m
                          <sup>2</sup>
                        </span>
                      </div>
                      <!-- end of amen -->
                    </div>
                  </div>
                  <!-- end of item -->
                </template>
              </VueSlickCarousel>
              <!--end of slick -->
              <div class="slidenav">
                <a
                  class="prev pointer cvr-bg"
                  @click="$refs.property1.prev()"
                ></a>
                <a
                  class="next pointer cvr-bg"
                  @click="$refs.property1.next()"
                ></a>
              </div>
            </div>
            <!-- end of slider -->
            <div class="action">
              <a href="#" @click="seeMore(2)" class="button">
                {{ $t("findProperty.FindAll.Button") }}
              </a>
            </div>
          </div>
          <!-- end of wrapper -->
        </div>
        <div class="list">
          <div class="wrapper">
            <h2 class="clearfix">
              {{ $t("findProperty.FindAll.Title2") }}
            </h2>
            <div class="slider rto">
              <VueSlickCarousel
                ref="property2"
                v-if="property.rent.length"
                v-bind="{
                  infinite: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  dots: false,
                  autoplay: false,
                  speed: 500,
                  autoplaySpeed: 5000,
                  variableWidth: false,
                  responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: false
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: true
                      }
                    }
                  ]
                }"
              >
                <template v-for="(property, index) in property.rent">
                  <div class="item item-property" :key="index">
                    <figure>
                      <router-link
                        v-if="property.propertyType"
                        :to="`/property/${property.slug}#rent`"
                        class="type"
                        :style="
                          `background-color: ${property.propertyType.class}`
                        "
                      >
                        {{ property.propertyType.name }}
                      </router-link>
                      <a
                        @click="bookmark(property.id)"
                        class="cvr-bg pointer bookmark"
                        :class="{ marked: property.isBookmarked }"
                      ></a>
                      <router-link :to="`/property/${property.slug}#rent`">
                        <template v-if="property.mediaDescription === null">
                          <img src="/img/dummy/home/1.jpg" />
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              checkType(property.mediaDescription[0]) == 'video'
                            "
                          >
                            <video style="width: 100%" controls autoplay>
                              <source
                                :src="property.mediaDescription[0]"
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </template>
                          <template v-else>
                            <img :src="property.mediaDescription[0]" />
                          </template>
                        </template>
                      </router-link>
                    </figure>
                    <div class="caption">
                      <h3>
                        <router-link :to="`/property/${property.slug}#rent`">
                          {{ property.name }}
                        </router-link>
                      </h3>
                      <div class="location cvr-bg-bf">
                        <span>{{ property.region }}, {{ property.city }}</span>
                        <span v-if="property.furnishedType">
                          {{ property.furnishedType.name }}
                        </span>
                      </div>
                      <!-- end of location -->
                      <strong class="price">
                        <b>Rp. {{ property.ratesMonthly | convertToRupiah }}</b>
                        <small> / {{ $t("findProperty.Month") }}</small>
                      </strong>
                      <div class="amen">
                        <span
                          class="cvr-bg-bf ic-bed"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBedroom }}
                        </span>
                        <span
                          class="cvr-bg-bf ic-bath"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBathroom }}
                        </span>
                        <span class="cvr-bg-bf ic-land">
                          {{ property.landSize | convertToRupiah }} m<sup
                            >2</sup
                          >
                        </span>
                        <span class="cvr-bg-bf ic-build">
                          {{ property.buildingSize | convertToRupiah }} m
                          <sup>2</sup>
                        </span>
                      </div>
                      <!-- end of amen -->
                    </div>
                  </div>
                  <!-- end of item -->
                </template>
              </VueSlickCarousel>
              <!--end of slick -->
              <div class="slidenav">
                <a
                  class="prev pointer cvr-bg"
                  @click="$refs.property2.prev()"
                ></a>
                <a
                  class="next pointer cvr-bg"
                  @click="$refs.property2.next()"
                ></a>
              </div>
            </div>
            <!-- end of slider -->
            <div class="action">
              <a href="#" @click="seeMore(1)" class="button">
                {{ $t("findProperty.FindAll.Button") }}
              </a>
            </div>
          </div>
          <!-- end of wrapper -->
        </div>
        <div class="list">
          <div class="wrapper">
            <h2 class="clearfix">
              {{ $t("findProperty.FindAll.Title3") }}
            </h2>
            <div class="slider rto">
              <VueSlickCarousel
                ref="property3"
                v-if="property.buy.length"
                v-bind="{
                  infinite: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  dots: false,
                  autoplay: false,
                  speed: 500,
                  autoplaySpeed: 5000,
                  variableWidth: false,
                  responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: false
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: true
                      }
                    }
                  ]
                }"
              >
                <template v-for="(property, index) in property.buy">
                  <div class="item item-property" :key="index">
                    <figure>
                      <router-link
                        :to="`/property/${property.slug}#buy`"
                        class="type"
                        :style="
                          `background-color: ${property.propertyType.class}`
                        "
                        v-if="property.propertyType"
                      >
                        {{ property.propertyType.name }}
                      </router-link>
                      <a
                        @click="bookmark(property.id)"
                        class="cvr-bg pointer bookmark"
                        :class="{ marked: property.isBookmarked }"
                      ></a>
                      <router-link :to="`/property/${property.slug}#buy`">
                        <template v-if="property.mediaDescription === null">
                          <img src="/img/dummy/home/1.jpg" />
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              checkType(property.mediaDescription[0]) == 'video'
                            "
                          >
                            <video style="width: 100%" controls autoplay>
                              <source
                                :src="property.mediaDescription[0]"
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </template>
                          <template v-else>
                            <img :src="property.mediaDescription[0]" />
                          </template>
                        </template>
                      </router-link>
                    </figure>
                    <div class="caption">
                      <h3>
                        <router-link :to="`/property/${property.slug}#buy`">
                          {{ property.name }}
                        </router-link>
                      </h3>
                      <div class="location cvr-bg-bf">
                        <span>{{ property.region }}, {{ property.city }}</span>
                        <span v-if="property.furnishedType">
                          {{ property.furnishedType.name }}
                        </span>
                      </div>
                      <!-- end of location -->
                      <strong class="price price-buy">
                        <em>Rp. {{ property.rates | convertToRupiah }}</em>
                      </strong>
                      <div class="amen">
                        <span
                          class="cvr-bg-bf ic-bed"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBedroom }}
                        </span>
                        <span
                          class="cvr-bg-bf ic-bath"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBathroom }}
                        </span>
                        <span class="cvr-bg-bf ic-land">
                          {{ property.landSize | convertToRupiah }} m<sup
                            >2</sup
                          >
                        </span>
                        <span class="cvr-bg-bf ic-build">
                          {{ property.buildingSize | convertToRupiah }} m
                          <sup>2</sup>
                        </span>
                      </div>
                      <!-- end of amen -->
                    </div>
                  </div>
                  <!-- end of item -->
                </template>
              </VueSlickCarousel>
              <!--end of slick -->
              <div class="slidenav">
                <a
                  class="prev pointer cvr-bg"
                  @click="$refs.property3.prev()"
                ></a>
                <a
                  class="next pointer cvr-bg"
                  @click="$refs.property3.next()"
                ></a>
              </div>
            </div>
            <!-- end of slider -->
            <div class="action">
              <a href="#" @click="seeMore(3)" class="button">
                {{ $t("findProperty.FindAll.Button") }}
              </a>
            </div>
          </div>
          <!-- end of wrapper -->
        </div>
        <!-- end of list -->
      </div>
      <!-- end of property -->
    </template>
    <template v-else>
      <div class="sorting">
        <div class="wrapper">
          <div class="fselect orange" style="width: auto;">
            <span v-if="sortBy">{{ sortBy.name }}</span>
            <span v-else>Sort By</span>
            <ul>
              <li v-for="(sort, index) in sorting" :key="index">
                <a class="pointer" @click="sortBy = sort">{{ sort.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- end of sorting -->
      <div class="property">
        <div class="list">
          <div class="wrapper">
            <div class="wrapitem">
              <template v-for="(property, index) in listing">
                <div class="item" :key="index">
                  <figure>
                    <router-link
                      :to="`/property/${property.slug}#${transactionText}`"
                      class="type"
                      :style="
                        `background-color: ${property.propertyType.class}`
                      "
                      v-if="property.propertyType"
                    >
                      {{ property.propertyType.name }}
                    </router-link>
                    <a
                      @click="bookmark(property.id)"
                      class="cvr-bg pointer bookmark"
                      :class="{ marked: property.isBookmarked }"
                    ></a>
                    <router-link
                      :to="`/property/${property.slug}#${transactionText}`"
                    >
                      <template v-if="property.mediaDescription === null">
                        <img src="/img/dummy/home/1.jpg" />
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            checkType(property.mediaDescription[0]) == 'video'
                          "
                        >
                          <video style="width: 100%" controls autoplay>
                            <source
                              :src="property.mediaDescription[0]"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </template>
                        <template v-else>
                          <img
                            :src="property.mediaDescription[0]"
                            style="max-height: 250px"
                          />
                        </template>
                      </template>
                    </router-link>
                  </figure>
                  <div class="caption">
                    <h3>
                      <router-link
                        :to="`/property/${property.slug}#${transactionText}`"
                      >
                        {{ property.name }}
                      </router-link>
                    </h3>
                    <div class="location cvr-bg-bf">
                      <span>{{ property.region }}, {{ property.city }}</span>
                      <span v-if="property.furnishedType">
                        {{ property.furnishedType.name }}
                      </span>
                    </div>
                    <!-- end of location -->
                    <template v-if="form.transactionType == 1">
                      <strong class="price">
                        <b>Rp. {{ property.ratesMonthly | convertToRupiah }}</b>
                        <small> / {{ $t("findProperty.Month") }}</small>
                      </strong>
                    </template>
                    <template v-else-if="form.transactionType == 2">
                      <strong class="price">
                        <b>
                          Rp.
                          {{
                            property.ratesAfterCalulate.ratePerMonth
                              | convertToRupiah
                          }}
                        </b>
                        <small> / {{ $t("findProperty.Month") }}</small>
                        <br />
                        <b style="color: #093763">
                          Rp.
                          {{
                            property.ratesAfterCalulate
                              .propertyPriceEndOfRentalPeriod | convertToRupiah
                          }}
                        </b>
                      </strong>
                    </template>
                    <template v-else>
                      <strong class="price price-buy">
                        <em> Rp. {{ property.rates | convertToRupiah }} </em>
                      </strong>
                    </template>
                    <div class="amen">
                      <span
                        class="cvr-bg-bf ic-bed"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBedroom }}
                      </span>
                      <span
                        class="cvr-bg-bf ic-bath"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBathroom }}
                      </span>
                      <span class="cvr-bg-bf ic-land">
                        {{ property.landSize | convertToRupiah }} m<sup>2</sup>
                      </span>
                      <span class="cvr-bg-bf ic-build">
                        {{ property.buildingSize | convertToRupiah }} m
                        <sup>2</sup>
                      </span>
                    </div>
                    <!-- end of amen -->
                  </div>
                  <!-- end of caption -->
                </div>
                <!-- end of item -->
              </template>
            </div>
            <!-- end of wrapitem -->
            <pagination
              :total-pages="totalPages"
              :total="totalPages"
              :per-page="perPage"
              :current-page="currentPage"
              @pagechanged="onPageChange"
            ></pagination>
            <!-- end of pagination -->
          </div>
          <!-- end of wrapper -->
        </div>
        <!-- end of list -->
      </div>
      <!-- end of property -->
    </template>
    <div class="info">
      <div class="wrapper">
        <p v-html="$t('findProperty.Information')"></p>
      </div>
    </div>
    <button
      data-src="#login-register"
      type="button"
      class="vhidden"
      data-fancybox
      ref="logreg"
    ></button>
  </div>
  <!-- end of inner about -->
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import $ from "jquery";
import Pagination from "@/components/Pagination";
import cookie from "js-cookie";

export default {
  name: "FindProperty",
  metaInfo: {
    title: "Find Property"
  },
  components: {
    VueSlickCarousel,
    Pagination
  },
  data() {
    return {
      recomendation: true,
      form: {
        transactionType: null,
        search: null,
        bedroom: null,
        propertyType: null,
        furnishedType: null,
        rentPeriod: null,
        province: null,
        city: null,
        area: null,
        status: [],
        bathroom: [],
        carlot: [],
        minRentPrice: null,
        maxRentPrice: null,
        minSalesPrice: null,
        maxSalesPrice: null,
        minLandSize: null,
        maxLandSize: null,
        minBuildingSize: null,
        maxBuildingSize: null
      },
      property: {
        rentToOwn: [],
        rent: [],
        buy: []
      },
      propertyType: [],
      furnishedType: [],
      rentPeriods: [],
      bedrooms: [
        {
          value: 1,
          name: 1
        },
        {
          value: 2,
          name: 2
        },
        {
          value: 3,
          name: 3
        },
        {
          value: "gte:4",
          name: "4+"
        }
      ],
      provinces: [],
      cities: [],
      areas: [],
      sorting: [
        {
          value: "rates.desc",
          name: this.$t("findProperty.Sorting.HPrice")
        },
        {
          value: "rates.asc",
          name: this.$t("findProperty.Sorting.LPrice")
        },
        {
          value: "createdAt.desc",
          name: this.$t("findProperty.Sorting.NL")
        }
      ],
      sortBy: {
        value: "createdAt.desc",
        name: this.$t("findProperty.Sorting.NL")
      },
      listing: [],
      currentPage: 1,
      perPage: 15,
      totalData: 1,
      totalPages: 1
    };
  },
  computed: {
    disableBedRoom() {
      if (
        this.form.propertyType == 1 || // House
        this.form.propertyType == 2 || // Apartment
        this.form.propertyType == 8 || // SOHO
        this.form.propertyType == 9 || // Vila
        this.form.propertyType === null
      ) {
        return false;
      } else {
        return true;
      }
    },
    rentPriceParam() {
      if (this.form.minRentPrice !== null && this.form.maxRentPrice === null) {
        return `gte:${this.form.minRentPrice}`;
      } else if (
        this.form.minRentPrice === null &&
        this.form.maxRentPrice !== null
      ) {
        return `lte:${this.form.maxRentPrice}`;
      } else if (
        this.form.minRentPrice !== null &&
        this.form.maxRentPrice !== null
      ) {
        return `between:${this.form.minRentPrice},${this.form.maxRentPrice}`;
      } else {
        return null;
      }
    },
    salesPriceParam() {
      if (
        this.form.minSalesPrice !== null &&
        this.form.maxSalesPrice === null
      ) {
        return `gte:${this.form.minSalesPrice}`;
      } else if (
        this.form.minSalesPrice === null &&
        this.form.maxSalesPrice !== null
      ) {
        return `lte:${this.form.maxSalesPrice}`;
      } else if (
        this.form.minSalesPrice !== null &&
        this.form.maxSalesPrice !== null
      ) {
        return `between:${this.form.minSalesPrice},${this.form.maxSalesPrice}`;
      } else {
        return null;
      }
    },
    landSizeParam() {
      if (this.form.minLandSize !== null && this.form.maxLandSize === null) {
        return `gte:${this.form.minLandSize}`;
      } else if (
        this.form.minLandSize === null &&
        this.form.maxLandSize !== null
      ) {
        return `lte:${this.form.maxLandSize}`;
      } else if (
        this.form.minLandSize !== null &&
        this.form.maxLandSize !== null
      ) {
        return `between:${this.form.minLandSize},${this.form.maxLandSize}`;
      } else {
        return null;
      }
    },
    buildingSizeParam() {
      if (
        this.form.minBuildingSize !== null &&
        this.form.maxBuildingSize === null
      ) {
        return `gte:${this.form.minBuildingSize}`;
      } else if (
        this.form.minBuildingSize === null &&
        this.form.maxBuildingSize !== null
      ) {
        return `lte:${this.form.maxBuildingSize}`;
      } else if (
        this.form.minBuildingSize !== null &&
        this.form.maxBuildingSize !== null
      ) {
        return `between:${this.form.minBuildingSize},${this.form.maxBuildingSize}`;
      } else {
        return null;
      }
    },
    bathroomParam() {
      if (this.form.bathroom.length > 0) {
        return `in:${this.form.bathroom.join(",")}`;
      } else {
        return null;
      }
    },
    carlotParam() {
      if (this.form.carlot.length > 0) {
        return `in:${this.form.carlot.join(",")}`;
      } else {
        return null;
      }
    },
    statusParam() {
      if (this.form.status.length > 0) {
        return `in:${this.form.status.join(",")}`;
      } else {
        return null;
      }
    },
    transaction() {
      if (this.form.transactionType == 1) {
        return "like:1";
      } else if (this.form.transactionType == 2) {
        return "like:2";
      } else if (this.form.transactionType == 3) {
        return "like:3";
      } else {
        return null;
      }
    },
    transactionText() {
      let text;
      if (this.form.transactionType === 1) {
        text = "rent";
      } else if (this.form.transactionType === 2) {
        text = "rent-to-own";
      } else if (this.form.transactionType === 3) {
        text = "buy";
      }
      return text;
    }
  },
  watch: {
    disableBedRoom(newVal) {
      if (newVal === true) {
        this.form.bedroom = null;
      }
    },
    currentPage() {
      this.searchProperty();
    },
    form: {
      handler() {
        if (this.currentPage == 1) {
          this.searchProperty();
        } else {
          this.currentPage = 1;
        }
      },
      deep: true
    },
    sortBy() {
      if (this.currentPage == 1) {
        this.searchProperty();
      } else {
        this.currentPage = 1;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (from.name === "DetailProperty") {
        // access to component public instance via `vm`
        const history = cookie.getJSON("search");
        if (history.province !== null) {
          await vm.getProvinces();
        }
        vm.form.transactionType = history.transactionType;
        vm.form.search = history.search;
        vm.form.bedroom = history.bedroom;
        vm.form.propertyType = history.propertyType;
        vm.form.furnishedType = history.furnishedType;
        vm.form.rentPeriod = history.rentPeriod;
        vm.form.province = history.province;
        vm.form.city = history.city;
        vm.form.area = history.area;
        vm.form.status = history.status;
        vm.form.bathroom = history.bathroom;
        vm.form.carlot = history.carlot;
        vm.form.minRentPrice = history.minRentPrice;
        vm.form.maxRentPrice = history.maxRentPrice;
        vm.form.minSalesPrice = history.minSalesPrice;
        vm.form.maxSalesPrice = history.maxSalesPrice;
        vm.form.minLandSize = history.minLandSize;
        vm.form.maxLandSize = history.maxLandSize;
        vm.form.minBuildingSize = history.minBuildingSize;
        vm.form.maxBuildingSize = history.maxBuildingSize;
      }
    });
  },
  async mounted() {
    $(".popup-addprop .close").on("click", function(e) {
      e.preventDefault();
      $(this)
        .closest(".popup-addprop")
        .find(".fancybox-close-small")
        .trigger("click");
    });

    this.getContent();
    await this.getPropertyType();
    await this.getFurnishedType();
    await this.getRentPeriod();
    await this.getProvinces();

    if (
      this.$route.query.transactionType !== undefined &&
      this.$route.query.transactionType != ""
    ) {
      this.form.transactionType = this.$route.query.transactionType;
    }
    if (
      this.$route.query.search !== undefined &&
      this.$route.query.search != ""
    ) {
      this.form.search = this.$route.query.search;
    }
    if (
      this.$route.query.propertyType !== undefined &&
      this.$route.query.propertyType != ""
    ) {
      this.form.propertyType = this.$route.query.propertyType;
    }
    if (
      this.$route.query.bedroom !== undefined &&
      this.$route.query.bedroom != ""
    ) {
      this.form.bedroom = this.$route.query.bedroom;
    }
    if (
      this.$route.query.furnishedType !== undefined &&
      this.$route.query.furnishedType != ""
    ) {
      this.form.furnishedType = this.$route.query.furnishedType;
    }
    if (
      this.$route.query.rentPeriod !== undefined &&
      this.$route.query.rentPeriod != ""
    ) {
      this.form.rentPeriod = this.$route.query.rentPeriod;
    }
    if (
      this.$route.query.province !== undefined &&
      this.$route.query.province != ""
    ) {
      this.form.province = this.$route.query.province;
      this.getCities();
    }
    if (this.$route.query.city !== undefined && this.$route.query.city != "") {
      this.form.city = this.$route.query.city;
      this.getAreas();
    }
    if (this.$route.query.area !== undefined && this.$route.query.area != "") {
      this.form.area = this.$route.query.area;
    }
    if (
      this.$route.query.status !== undefined &&
      this.$route.query.status != ""
    ) {
      this.form.status = this.$route.query.status.replace("in:", "").split(",");
    }
    if (
      this.$route.query.bathroom !== undefined &&
      this.$route.query.bathroom != ""
    ) {
      this.form.bathroom = this.$route.query.bathroom
        .replace("in:", "")
        .split(",");
    }
    if (
      this.$route.query.carlot !== undefined &&
      this.$route.query.carlot != ""
    ) {
      this.form.carlot = this.$route.query.carlot.replace("in:", "").split(",");
    }
    if (
      this.$route.query.minRentPrice !== undefined &&
      this.$route.query.minRentPrice != ""
    ) {
      this.form.minRentPrice = this.$route.query.minRentPrice;
    }
    if (
      this.$route.query.maxRentPrice !== undefined &&
      this.$route.query.maxRentPrice != ""
    ) {
      this.form.maxRentPrice = this.$route.query.maxRentPrice;
    }
    if (
      this.$route.query.minSalesPrice !== undefined &&
      this.$route.query.minSalesPrice != ""
    ) {
      this.form.minSalesPrice = this.$route.query.minSalesPrice;
    }
    if (
      this.$route.query.maxSalesPrice !== undefined &&
      this.$route.query.maxSalesPrice != ""
    ) {
      this.form.maxSalesPrice = this.$route.query.maxSalesPrice;
    }
    if (
      this.$route.query.minLandSize !== undefined &&
      this.$route.query.minLandSize != ""
    ) {
      this.form.minLandSize = this.$route.query.minLandSize;
    }
    if (
      this.$route.query.maxLandSize !== undefined &&
      this.$route.query.maxLandSize != ""
    ) {
      this.form.maxLandSize = this.$route.query.maxLandSize;
    }
    if (
      this.$route.query.minBuildingSize !== undefined &&
      this.$route.query.minBuildingSize != ""
    ) {
      this.form.minBuildingSize = this.$route.query.minBuildingSize;
    }
    if (
      this.$route.query.maxBuildingSize !== undefined &&
      this.$route.query.maxBuildingSize != ""
    ) {
      this.form.maxBuildingSize = this.$route.query.maxBuildingSize;
    }
  },
  methods: {
    async getPropertyType() {
      this.$axios
        .get(`/misc/property-types`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.propertyType = response.data.data;
        });
    },
    async getFurnishedType() {
      this.$axios.get(`/misc/furnished-types`).then(response => {
        this.furnishedType = response.data.data;
      });
    },
    async getRentPeriod() {
      this.$axios.get(`/misc/rent-periods`).then(response => {
        this.rentPeriods = response.data.data;
      });
    },
    async getProvinces() {
      this.$axios.get(`/misc/provinces`).then(response => {
        this.provinces = response.data.data.filter(item => item !== null);
      });
    },
    getCities() {
      this.form.city = null;
      this.$axios.get(`/misc/cities/${this.form.province}`).then(response => {
        this.cities = response.data.data;
      });
    },
    getAreas() {
      this.form.area = null;
      this.$axios.get(`/misc/postal-codes/${this.form.city}`).then(response => {
        this.areas = response.data.data;
      });
    },
    getContent() {
      this.$axios
        .get(`/pages/home`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.property.rent = response.data.data.quickRent;
          this.property.rentToOwn = response.data.data.quickRentToOwn;
          this.property.buy = response.data.data.quickBuy;
        });
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    seeMore(transactionType) {
      this.form.transactionType = transactionType;
      this.searchProperty();
    },
    formatRupiah(model) {
      const angka = this.convertToAngka(this.form[model]);
      const rupiah = this.convertToRupiah(angka, false);
      this.form[model] = rupiah;
    },
    resetPopUpForm() {
      this.form.province = null;
      this.form.city = null;
      this.form.area = null;
      this.form.status = [];
      this.form.bathroom = [];
      this.form.carlot = [];
      this.form.minSalesPrice = null;
      this.form.maxSalesPrice = null;
      this.form.minRentPrice = null;
      this.form.maxRentPrice = null;
      this.form.minLandSize = null;
      this.form.maxLandSize = null;
      this.form.minBuildingSize = null;
      this.form.maxBuildingSize = null;
    },
    searchProperty() {
      const searchHistory = {
        transactionType: this.form.transactionType,
        search: this.form.search,
        bedroom: this.form.bedroom,
        propertyType: this.form.propertyType,
        furnishedType: this.form.furnishedType,
        rentPeriod: this.form.rentPeriod,
        province: this.form.province,
        city: this.form.city,
        area: this.form.area,
        status: this.form.status,
        bathroom: this.form.bathroom,
        carlot: this.form.carlot,
        minRentPrice: this.form.minRentPrice,
        maxRentPrice: this.form.maxRentPrice,
        minSalesPrice: this.form.minSalesPrice,
        maxSalesPrice: this.form.maxSalesPrice,
        minLandSize: this.form.minLandSize,
        maxLandSize: this.form.maxLandSize,
        minBuildingSize: this.form.minBuildingSize,
        maxBuildingSize: this.form.maxBuildingSize
      };
      cookie.set("search", searchHistory);
      this.recomendation = false;
      this.$axios
        .get(`/properties/find`, {
          params: {
            page: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy.value,
            transactionType: this.transaction,
            search: this.form.search,
            propertyTypeId: this.form.propertyType,
            numBedroom: this.form.bedroom,
            furnishedTypeId: this.form.furnishedType,
            region: this.form.province,
            city: this.form.city,
            zip: this.form.area,
            numBathroom: this.bathroomParam,
            numCarLot: this.carlotParam,
            status: this.statusParam,
            rates: this.salesPriceParam,
            ratesMonthly: this.rentPriceParam,
            landSize: this.landSizeParam,
            buildingSize: this.buildingSizeParam,
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.listing = response.data.data.rows;
          this.totalData = response.data.data.count;
          this.totalPages = response.data.data.totalPages;
        });
    },
    bookmark(propertyId) {
      if (this.$store.state.userdata) {
        this.$axios
          .post("/properties/bookmarks", {
            id: propertyId
          })
          .then(response => {
            this.$swal({
              icon: "success",
              text: response.data.message
            });
          });
      } else {
        this.$refs.logreg.click();
      }
    }
  }
};
</script>

<style scoped>
.item {
  margin-left: 0px !important;
}
.tabing {
  cursor: pointer !important;
}
.toleft {
  left: 0 !important;
  right: auto !important;
}
input.price {
  width: 165px !important;
  margin-left: 42px !important;
  padding-right: 0px !important;
}
select:disabled {
  cursor: not-allowed !important;
}
</style>
